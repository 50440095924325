import React from "react"

import AltLayout from "../components/altlayout"
import SEO from "../components/seo"
import ContactInfo from "../components/ContactInfo/contactinfo"
import PageTitle from "../components/PageTitle/pagetitle"
import Footer from "../components/Footer/footer"

const ContactPage = () => (
  <AltLayout>
    <SEO title="Contact" />
    <PageTitle title="Contact" />
    <ContactInfo />
    <Footer />
  </AltLayout>
)

export default ContactPage
