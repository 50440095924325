import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"
import ContactData from "../../Data/contact-info.json"
import ContentContainer from "../ContentContainer/contentcontainer"

const Container = styled.div`
    text-align: center;
    .heading{
        font-family: bookmania;
        font-size: 1.4em;
        font-weight: 600;
    }
    .contact-box{
        text-align: center;
        width: 33%;
        display: inline-block;
        vertical-align: top;
    }
    .sub-head{
        font-size: 1em;
        font-family: gibson;
        font-weight: 100;
        letter-spacing: 1px;
        color: #5F5F5F;
        margin-bottom: 10px;
    }
    a{
        font-family: gibson;
        font-weight: 100;
        text-transform: uppercase;
        color: #DB995A;
        letter-spacing: 1px;
    }
    .form-title{
        width: 100%;
        font-family: bookmania;
        font-weight: 600;
        text-align: center;
        font-size: 2.4em;
        padding-top: 50px;
    }
    p{
        font-family: open sans;
        font-weight: 100;
        font-size: 1em;
        margin-bottom: 20px;
    }
    form{
        text-align: left;
        width: 600px;
        margin: auto;
        margin-top: 20px;
    }
    label{
        font-family: open sans;
        font-weight: 400;
        
    }
    input, textarea{
        border: 1px solid #D5D5D5;
        height: 40px;
        width: 100%;
        margin-bottom: 25px;
        padding: 0px 20px;
    }
    input[type=submit]{
        width: 150px;
        background-color: #DB995A;
        color: white;
        border: none;
    }
    textarea{
        height: 200px;
    }
    @media(max-width:1050px ){
        .contact-box{
            width: 100%;
            display: block;
        }
        .contact-box:not(:last-child){
            margin-bottom: 50px;
        }
    }
    @media(max-width: 650px){
        form{
            width: 100%;
            margin: auto;
        }
        .form-title{
            font-size: 1.6em;
        }
    }
`

function ContactInfo(){
    return(
        <Container>
            <ContentContainer>
                <div className="contact-box">
                    <div className="heading">
                        {ContactData.phone_number}
                    </div>
                    <div className="sub-head">Phone Number</div>
                </div>
                <div className="contact-box">
                    <div className="heading">
                        Creekside Oaks Estates
                    </div>
                    <div className="sub-head">{ContactData.address_street}, {ContactData.address_city} {ContactData.address_state} {ContactData.address_zip}</div>
                    <a href="https://www.google.com/maps/place/2+Creekside+Oaks,+Martinez,+CA+94553/@37.9660688,-122.1310287,177m/data=!3m1!1e3!4m5!3m4!1s0x808564f86a7b01ad:0xc4e380f4489677ec!8m2!3d37.966662!4d-122.13057">Get Directions</a>
                </div>
                <div className="contact-box">
                     <div className="heading">
                        {ContactData.email}
                    </div>
                    <div className="sub-head">Email Address</div>
                </div>
                <div className="form-title">
                    Interested In Creekside Oaks?
                </div>
                <p>Feel free to send us any questions you may have. We are happy to answer them.</p>
                <form name="contact" method="POST" data-netlify="true" netlify>
                    <label for="fname">Your Name</label><br />
                    <input type="text" />
                    <label for="fname">Your Email</label><br />
                    <input type="text" />
                    <label for="fname">Your Phone Number</label><br />
                    <input type="text" />
                    <label for="fname">Your Message</label><br />
                    <textarea className="message" name="message" />
                    <input type="submit" value="Submit"/>
                </form>
            </ContentContainer>
        </Container>
    )
}

export default ContactInfo